import getAllClientsAndMatters from "app/matters/queries/getAllClientsAndMatters"
import { invoke } from "blitz"
import { Client, Matter } from "db"
import Dexie from "dexie"
import { cloneDeep, merge } from "lodash"
import { action, computed, makeObservable, observable } from "mobx"

class MatterStoreDatabase extends Dexie {
  public matters: Dexie.Table<Matter, number> // id is number in this case
  public clients: Dexie.Table<
    Client & {
      matters: Matter[]
    },
    number
  > // id is number in this case

  public constructor() {
    super("MatterStoreDatabase")
    this.version(1).stores({
      matters: "id,file,clientId",
      clients: "id,name",
    })
    this.matters = this.table("matters")
    this.clients = this.table("clients")
  }
}

const matterStoreDatabase = new MatterStoreDatabase()

type ClientWithMatters = Client & { matters?: Matter[] }

export class MatterStore {
  clientArray: ClientWithMatters[] = [] // The matters portion of this may be stale. So don't use in practice.
  matterArray: Matter[] = []

  initialLoad: boolean = false
  finishLoad: boolean = false

  constructor() {
    makeObservable(this, {
      load: action,
      finishLoad: observable,
      initialLoad: observable,
      setFinishLoad: action,
      setInitialLoad: action,
      clientsWithMatters: computed,
    })
  }

  // // Deprecated, remove
  // clients() {
  //   const flattened = Array.from(this.clientsWithMatters.keys()).flat()
  //   return flattened
  // }

  // // Deprecated, remove
  // matters() {
  //   const flattened = Array.from(this.clientsWithMatters.values()).flat()
  //   return flattened
  // }

  findClientFromId(clientId: number) {
    const clients = this.clientArray
    return clients.find((m) => clientId === m.id)
  }

  findMatterFromId(matterId: number) {
    const matters = this.matterArray
    return matters.find((m) => matterId === m.id)
  }

  findMatterFromFileNumber(file: string) {
    const matters = this.matterArray
    return matters.find((m) => file === m.file)
  }

  findClientFromMatter(matterId: number) {
    return this.clientArray.find((c) => c.matters?.find((m) => m.id === matterId))
  }

  setFinishLoad = (): void => {
    this.finishLoad = true
  }

  setInitialLoad = (): void => {
    this.initialLoad = true
  }

  upsertMatterById(matterId: number, matter: Matter | Partial<Matter>) {
    const exists = this.matterArray.find((c) => c.id === matterId)
    if (exists) {
      merge(exists, matter)
    } else {
      this.matterArray.push(cloneDeep(matter as Matter))
    }
  }

  upsertClientById(clientId: number, client: Client | Partial<Client>) {
    const exists = this.clientArray.find((c) => c.id === clientId)
    if (exists) {
      merge(exists, client)
    } else {
      this.clientArray.push(cloneDeep(client as Client))
    }
  }

  get clientsWithOnlyActiveMatters() {
    // This may be a stale value if clients are added before a refresh.
    const clientsWithMatters: Map<Client, Array<Matter>> = new Map() // Reset clientsWithMatters otherwise the following will create duplicates... bad!
    this.clientArray
      .filter((client) => !client.archived)
      .forEach((c) => {
        clientsWithMatters.set(c, c.matters?.filter((matter) => !matter.archived) ?? [])
      })
    return clientsWithMatters
  }

  get clientsWithMatters() {
    // This may be a stale value if clients are added before a refresh.
    const clientsWithMatters: Map<Client, Array<Matter>> = new Map() // Reset clientsWithMatters otherwise the following will create duplicates... bad!
    this.clientArray.forEach((c) => {
      clientsWithMatters.set(c, c.matters ?? [])
    })
    return clientsWithMatters
  }

  inMemoryInitialization = (clientsWithMattersArray: ClientWithMatters[]) => {
    this.clientArray = cloneDeep(clientsWithMattersArray)
    this.matterArray = clientsWithMattersArray.map((c) => c.matters!).flat()
  }

  load = async () => {
    if (!this.initialLoad) {
      this.setInitialLoad()

      const clientsWithMatters = await matterStoreDatabase.clients.toArray()
      this.inMemoryInitialization(clientsWithMatters)

      if (clientsWithMatters.length > 0) {
        // Only set the load as finished iff there are cached clients.
        this.setFinishLoad()
      }

      setTimeout(async () => {
        const clientsWithMatters = await invoke(getAllClientsAndMatters, {})
        // TODO: URGENT: Delete missing matters / clients from the database.
        matterStoreDatabase.clients.bulkPut(clientsWithMatters)
        matterStoreDatabase.matters.bulkPut(clientsWithMatters.map((c) => c.matters).flat())
        this.inMemoryInitialization(clientsWithMatters)
        this.setFinishLoad()
      }, 2000)
    }
  }
}
